.hero {
  position: relative;
  padding-left: 200px;
}
.hero h3 {
  font-weight: 100;
  letter-spacing: 2px;
}
.hero h1 {
  font-size: 60px;
  font-weight: bold;
}

.hero h1 span {
  color: #01699e;
}
.hero h2 {
  font-size: 50px;
  font-weight: bold;
  color: #01699e;
}
.hero h2 span {
  color: black;
}
.hero p {
  color: #878e99;
  font-size: 24px;
  line-height: 30px;
  margin-top: 30px;
}
.hero_btn {
  margin-top: 10%;
}
.hero_btn h4 {
  font-weight: 500;
  letter-spacing: 2px;
  color: #000000;
  font-size: 15px;
  margin: 30px 0 30px 0;
}
.hero_btn button {
  margin-right: 30px;
}
.hero_btn i {
  font-size: 20px;
}

.hero_btn button img {
  width: 20px;
  height: 20px;
}
.hero .left {
  margin-right: 10%;
}
.hero .right {
  width: 50%;
  position: relative;
  margin-left: 10%;
}

.hero .right_img img {
  /* position: absolute; */
  top: 0;
  right: 10%;
  width: 70%;
  height: fit-content;
}
.hero .f_flex {
  display: flex;
  justify-content: space-around;
}
@media (max-width: 768px) {
  .hero h1 {
    margin-top: 10px;
    font-size: 40px; 
  }

  .hero h2 {
    font-size: 30px; 
  }
  .hero p {
    font-size: 16px; 
  }

  .hero .right_img img {  
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    width: 80%; 
    max-height: 120vh;
    object-fit: cover; 
  }

  .hero .right,
  .hero .left {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }

  .hero .f_flex {
    flex-direction: column;
    flex-direction: column-reverse;
  }
}